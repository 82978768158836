<template>
  <el-header class="my-el-header">
    <el-row>
      <el-col :span="4"><img src="@/assets/view_logo.png" class="header-img"/></el-col>
      <el-col :span="16" class="header-message">
        <el-menu :default-active="activeMenu()" mode="horizontal" class="el-menu-demo" :router="true">
          <el-menu-item index="/home">首页</el-menu-item>
          <el-menu-item index="/category/articles">文章分类</el-menu-item>
          <el-menu-item index="/tools/collect">工具收集</el-menu-item>
          <el-menu-item index="/about/auth">关于</el-menu-item>
        </el-menu>
      </el-col>
      
    </el-row>
  </el-header>
</template>

<script>
  export default {
    name: 'Navbar',

    data() {
      return {
        
      };
    },
    methods: {
      activeMenu(){
        const route = this.$route
        const {meta, path} = route
        if (meta.activeMenu) {
          return meta.activeMenu
        }
        return path
      }
    
    },
  };
</script>

<style scoped>
  .my-el-header {
    background-color: rgb(248, 248, 248);
    color: #333;
    text-align: center;
    /* line-height: 60px; */
    height: 61px !important;
    border-bottom: solid 1px #e6e6e6;
  }
  .el-menu-demo {
    background-color: rgb(248, 248, 248);
  }

  .el-menu-item {
    font-size: 18px;
  }

  .header-img {
    height: 60px;
    text-align: left;
  }
  .header-message {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>