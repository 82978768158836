import Vue from 'vue'
import App from './App.vue'

import 'normalize.css/normalize.css' // 重置css样式，页面样式保持统一

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

Vue.config.productionTip = false

// 引用路由
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import router from './router'

// 引用store
import store from './store'

// 全局注册引用mavon-editor，用于支持markdown笔记
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
Vue.use(mavonEditor)

new Vue({
  render: h => h(App),
  router,
  store,
  beforeCreate() {  // 安装全局事件总线，这个用多了会乱，少用
    Vue.prototype.$bus = this
  }
}).$mount('#app')
