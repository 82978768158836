const state = {
  articleObj: {}
}

const actions = {
  // 发送文章对象
  sendArticle(commit, article) {
    commit('SEND_ARTICLE', article)
  }
}

const mutations = {
  SEND_ARTICLE(state, article) {
    state.articleObj = article
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}