import VueRouter from "vue-router";
import Layout from "@/views/layout/Index"

const constantRoutes = [
  // {
  //   path: '/',
  //   component: Layout
  // },

  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        component: () => import('@/views/home/Home')
      }
    ]
  },

  {
    path: '/category',
    component: Layout,
    children: [
      {
        path: 'articles',
        component: () => import('@/views/category/Category')
      }
    ]
  },

  {
    path: '/tools',
    component: Layout,
    children: [
      {
        path: 'collect',
        component: () => import('@/views/tools/ToolsCollect')
      }
    ]
  },

  {
    path: '/about',
    component: Layout,
    children: [
      {
        path: 'auth',
        component: () => import('@/views/about/About')
      }
    ]
  },
  {
    path: '/markdown',
    component: Layout,
    children: [
      {
        path: 'view',
        component: () => import('@/components/markdown/MarkdownView')
      },
      {
        path: 'tree',
        component: () => import('@/components/tree/ArticleTree')
      }
    ]
  },

]

const router = new VueRouter({
  routes: constantRoutes
})

export default router;