<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <keep-alive>
        <router-view :key="key"/>
      </keep-alive>
    </transition>
  </section>
</template>

<script>
  export default {
    name: 'AppMain',

    data() {
      return {
        
      };
    },
    computed: {
      key() {
        return this.$route.path
      }

    }
  };
</script>

<style scoped>
  .app-main {
    height: calc(100% - 61px);
    /* background-color: aquamarine; */
    overflow: auto;
  }
</style>