<template>
  <el-container direction="vertical" class="container">
    <navbar/>
    <app-main/>
  </el-container>
</template>

<script>
  import Navbar from '@/views/layout/Navbar.vue'
  import AppMain from '@/views/layout/AppMain.vue'
  export default {
    name: 'Layout',
    components: {Navbar, AppMain},
    data() {
      return {
        
      };
    },
  };
</script>

<style scoped>
  .container {
    height: 100%;
    width: 100%;
  }
</style>